<template>
  <div id="app">
    <!-- background img -->
    <img class="bg" src="../../assets/icons/vcontroller-show-ac-control.png">
    <!-- top -->
    <div v-if="inputByValue" class="click-btn-box">
      <!-- <span class="logo">
        <el-upload ref="uploadLogoFileRef" list-type="picture" class="upload-demo" :limit="1" :show-file-list="false"
          with-credentials :before-upload="beforUpload" :on-change="imgPreview" :http-request="updateLogo"
          action="customize">
          <img v-if="logoImgPreview" :src="logoImgPreview" alt="LOGO">
          <img v-else-if="inputByValue.logo && inputByValue.id" :src="getLogo(inputByValue.id)" alt="LOGO">
          <img v-else src="../../assets/icons/vcontroller-logo@2x.png" alt="LOGO">
        </el-upload>
      </span> -->
      <div class="input-title-box">
        <div v-show="showTip" class="tip1">Edit title here</div>
        <div class="title">
          <input @click="editTextClick" class="title-input" v-model="inputByValue.title" placeholder="input title here" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // virtualController
  getVirtualControllerLogo
} from '../../api'
export default {
  props: {
    inputByValueDefault: Object,
  },
  data() {
    return {
      showTip: true,
      logoImgPreview: null,
      inputByValue: {
        title: '',
        target_temperature: 24,
        mode: 'fan',
        fan_speed: 'auto',
        power: false,
        logo: false,
      },
      logo: null,
    }
  },
  watch: {
    // 单个监听
    'inputByValue.title': function () {
      this.$emit('inputByValue', 'inputByValueBtn1', this.inputByValue)
    },
    'inputByValue.btn1': function () {
      this.$emit('inputByValue', 'inputByValueBtn1', this.inputByValue)
    },
    'inputByValue.logo': function () {
      this.$emit('inputByValue', 'inputByValueBtn1', this.inputByValue)
    },
    logo: function () {
      this.$emit('inputByValue', 'logo', this.logo)
    },
  },
  created: function () {
    // 输入框的值与父组件传进来的值关联
    console.log('this.inputByValueDefault =1> ', this.inputByValueDefault);
    this.inputByValue = this.inputByValueDefault
  },
  mounted: function () { },
  methods: {
    // edit
    editTextClick() {
      this.showTip = false
    },
    //图片缩略图
    imgPreview(file) {
      this.logo = file.raw
      this.logoImgPreview = file.url // 显示缩略图地址 blob:http://localhost:8080/xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
      this.inputByValue.logo = true
    },
    getLogo(id) {
      return getVirtualControllerLogo(id)
    },
    updateLogo() {
      // this.inputByValue.logo = file.file
    },
    beforUpload(file) {
      this.$refs.uploadLogoFileRef.clearFiles(); //上传前清除历史记录
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isJPG && !isPNG) {
        this.$message.error('Upload pictures can only be in JPG or PNG format!');
        this.logoImgPreview = null
      }
      if (!isLt1M) {
        this.$message.error('Upload image size cannot exceed 1MB!');
        this.logoImgPreview = null
      }
      return (isJPG || isPNG) && isLt1M;
    }
  }
}
</script>


<style scoped>
#app {
  width: 100%;
  height: 350px;
  position: relative;
  text-align: center;
}

#app>.bg {
  position: absolute;
  height: 350px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  display: block;
}


/* button  */
.click-btn-box {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.click-btn-box .logo img {
  margin-top: 15px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.click-btn-box .input-button-text {
  position: absolute;
  top: 0;
  width: 65%;
  text-align: center;
  background: transparent !important;
  border: none;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  outline: none;
}

.click-btn-box .btn1-input {
  margin-top: 20px;
  width: 70px;
}

.click-btn-box .title {
  font-weight: 600;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.click-btn-box .input-title-box {
  position: relative;
}
.click-btn-box .input-title-box .tip1 {
  height: 26px;
  width: 83px;
  background: url('../../assets/icons/vcontroller-tip.png') no-repeat;
  font-size: 11px;
  font-weight: 400;
  color: #000000;
  position: absolute;
  top:-5px;
  left: 50%;
  margin-left: 20px;
  transform: translateX(-50%);
}

.click-btn-box .title .title-input {
  margin-top: 18px;
  text-align: center;
  background: white;
  border: none;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  outline: none;
  width: 120px;
}


.click-btn-box .btn1 span {
  font-weight: 600;
  color: #FFFFFF;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: 600;
}

/* tip */


.click-btn-box .tip2 {
  font-size: 11px;
  font-weight: 400;
  color: #000000;
  margin-left: 50px;
  margin-top: 85px;
  position: relative;
  line-height: 30px;
  position: absolute;
  right: 20px;
  top: 125px;
}

.click-btn-box .tip2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 26px;
  width: 134px;
  border-radius: 2px;
  background: url('../../assets/icons/vcontroller-tip2.png') repeat;
}
</style>